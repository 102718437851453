<template>
  <div class="page-content" v-if="hiddenQuest">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Hidden Mission"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Hidden Mission</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Mission Name"
              label-for="h-mission-name"
              label-cols-md="3"
            >
              <ui-component :component-data="hiddenQuest.name" class="border" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Mission Description"
              label-for="h-mission-description"
              label-cols-md="3"
            >
              <ui-component
                :component-data="hiddenQuest.description"
                class="border"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Complete threshold"
              label-for="h-complete-threshold"
              label-cols-md="3"
            >
              <b-form-input
                v-model="hiddenQuest.complete_threshold"
                id="h-number"
                placeholder="Number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Daily quota"
              label-for="h-daily-quota"
              label-cols-md="3"
            >
              <b-form-input
                v-model="hiddenQuest.daily_quota"
                id="h-number"
                placeholder="Number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-for="(item, index) in hiddenQuest.rewards" :key="index">
          <b-col cols="12">
            <b-form-group label="Reward" label-for="h-reward" label-cols-md="3">
              <b-form-select v-model="item.type" :options="select_rewards" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Number" label-for="h-number" label-cols-md="3">
              <b-form-input
                v-model="item.amount"
                id="h-number"
                placeholder="Number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </section>

    <action-buttons
      update-button
      back-button
      @update="update"
      :is-updating="isUpdating"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
export default {
  data() {
    return {
      isUpdating: false,
      isDeleting: false,
      hiddenQuest: null,
      select_rewards: [
        {
          text: "Box",
          value: "BOX",
        },
      ],
    };
  },
  computed: {
    hiddenQuestId() {
      return this.$route.params.hidden_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Hidden Mission List",
          to: { name: "quest-hidden-list" },
        },
      ];
      items.push({ text: "Hidden Mission Detail", active: true });
      return items;
    },
  },
  async created() {
    this.getHiddenQuest();
  },
  methods: {
    async getHiddenQuest() {
      this.hiddenQuest = await service.get({ id: this.hiddenQuestId });
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.hiddenQuest),
      });
      this.isUpdating = false;
    },
  },
};
</script>
